<template>
	<div class="sign-up-content">
		<div class="row sign-up-header">
			<div class="col">
				<p class='sign-up-title'>{{$t("auth.sign_up.title")}}</p>
			</div>
			<div class="col-3">
				<v-select
					:label='$t("auth.sign_up.ownership")'
					name='ownership'
					:options='items_ownership'
					v-model='ownership'
					@input='resetForm()'
					required
				/>
			</div>
		</div>

		<ValidationObserver ref="form" v-slot="{ invalid }">
			<form @submit.prevent='onSubmit'>
				<template>
					<div class='sign-up-block' v-for='(item, i) in items.filter(el => el.ownership_hide !== ownership)' :key='i'>
						<p class='sign-up-sub-title' v-if='item.title'>{{ item.title }}</p>
						<div class="row">
							<div
								:class="field.col ? `col-${field.col}`: 'col'"
								v-for='(field, i) in item.fields'
								:key='i'
							>
								<template v-if='field.select'>
									<v-select
										:label='$t(`auth.sign_up.${field.name}`)'
										:name='field.value'
										:options='field.items'
										v-model='form[field.value]'
										required
									/>
								</template>
								<template v-else-if='field.datePicker'>
									<ValidationProvider
										:name='$t(`auth.sign_up.${field.name}`)'
										:rules="field.rules ? field.rules : { required: false }"
										v-slot='{errors}'
									>
										<div class="app-input">
											<label class="app-label" v-if="field.name">
												{{ $t(`auth.sign_up.${field.name}`) }}:
												<sup v-if="field.required">*</sup>
											</label>
											<div class="app-input__control">
												<date-picker
													v-model="form[field.value]"
													:placeholder='$t(`auth.sign_up.placeholder.${field.name}`)'
													:lang="$route.params.lang ? $route.params.lang : 'uk'"
													class='mx-datepicker-small'
													:disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
													:append-to-body='false'
													:editable='false'
												/>
												<div class="app-input__details">
													<div class="error--text" v-if="errors[0]">{{ errors[0] }}</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</template>
								<template v-else>
									<ValidationProvider
										:name='$t(`auth.sign_up.${field.name}`)'
										:rules="field.rules ? field.rules : { required: false }"
										v-slot='{errors, classes}'
									>
										<v-text-field
											:label='$t(`auth.sign_up.${field.name}`)'
											:placeholder='field.placeholder ? field.placeholder : $t(`auth.sign_up.placeholder.${field.name}`)'
											:name='field.name'
											:class='field.rules ? classes : ""'
											v-model='form[field.value]'
											:errorMessage='errors[0]'
                                            :key="field.name"
											:required='field.rules ? true : false'
											:mask='field.mask ? field.mask : false'
										/>
									</ValidationProvider>
								</template>
							</div>
						</div>
					</div>
				</template>

				<div class="row">
					<div class="col">
						<v-checkbox
							name='terms_check'
							:label='$t("auth.sign_up.checkbox")'
							v-model='form.agreement'
							required
						/>
					</div>
				</div>

				<div class="row sign-up-buttons">
					<div class="col-auto">
						<v-button color='green' outline :to="$i18nRoute({name: 'sign-in'})">{{$t('auth.sign_up.cancel')}}</v-button>
					</div>
					<div class="col-auto">
						<v-button color='green white--text' :disabled="invalid || !form.agreement" @click='onSubmit' :loading='loading'>{{$t('auth.sign_up.next')}}</v-button>
					</div>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
	import { registration } from '@/services/request';

	export default {
		data: () => ({
			loading: false,
			ownership: null,
			form: {
				operatesUnder: null,
				agreement: false
			},
			placeholder: {
				phone_number: '+380 50 000 54 54',
				email: 'company@banana.com.ua',
				iban: 'UA00 0000 0000 0000 0000 0000 0000 0',
				edrpou: "10000001",
				edrpou_registration: "01.01.2019, 0 000 000 0000 0000",
				ipn: "10000001",
				office_phone_number: "10000001",
				email_to_receive_documents: "documents@banana.com.ua",
				email_optional: "director@banana.com.ua",
				rnokpp: "1000000001"
			}
		}),
		computed: {
			items() {
				return [
					{ ownership_hide: 1, fields: [
						{ col: '3', name: 'name_company', value: 'companyName', rules: 'required' },
						{ col: '3', name: 'acts_on_the_basis', value: 'operatesUnder', rules: 'required', select: true, items: this.items_acts_on_the_basis },
						{ col: '3', name: 'edrpou', value: 'edrpou', rules: 'required|numeric|length:8', placeholder: this.placeholder.edrpou, mask: '########' },
						{ col: '3', name: 'edrpou_registration', value: 'registry', rules: 'required', placeholder: this.placeholder.edrpou_registration },
						{ col: '4', name: 'tax_address', value: 'addressTax', rules: 'required' },
						{ col: '4', name: 'tax_type', value: 'taxType', rules: 'required' },
						{ col: '4', name: 'office_address', value: 'addressOffice', rules: 'required' },
						{ col: '3', name: 'bank_guarantee', value: 'guarantee', rules: 'required' },
						{ col: '3', name: 'guarantee_expire_date', value: 'guaranteeExpireDate', rules: 'required', datePicker: true },
						{ col: '3', name: 'ipn', value: 'itn', rules: 'required|numeric|length:12', placeholder: this.placeholder.ipn, mask: '############' },
						{ col: '3', name: 'phone_number', value: 'telMobileReg', rules: 'required|numeric|length:12', placeholder: this.placeholder.phone_number, mask: '+380 ## ### ## ##' },
						{ col: '3', name: 'emergency_phone_number', value: 'telEmergency', rules: 'required|numeric|length:12', placeholder: this.placeholder.phone_number, mask: '+380 ## ### ## ##' },
						{ col: '3', name: 'email_to_receive_documents', value: 'emailExtra', rules: 'required|email', placeholder: this.placeholder.email_to_receive_documents },
						{ col: '3', name: 'email_optional', value: 'emailOffice', rules: 'required|email', placeholder: this.placeholder.email_optional },
						{ col: '3', name: 'name_signatory_of_the_contract', value: 'fullNameCompany', rules: 'required' }
					]},
					{ ownership_hide: 0, fields: [
						{ col: '3', name: 'last_name', value: 'surname', rules: 'required' },
						{ col: '3', name: 'first_name', value: 'name', rules: 'required' },
						{ col: '3', name: 'middle_name', value: 'patronymic', rules: 'required' },
						{ col: '3', name: 'acts_on_the_basis', value: 'operatesUnder', rules: 'required', select: true, items: this.items_acts_on_the_basis },
						{ col: '3', name: 'rnokpp', value: 'rnokpp', rules: 'required|numeric', placeholder: this.placeholder.rnokpp },
						{ col: '3', name: 'edrpou_registration', value: 'registry', rules: 'required', placeholder: this.placeholder.edrpou_registration },
						{ col: '6', name: 'tax_address', value: 'addressTax', rules: 'required' },
						{ col: '4', name: 'tax_type', value: 'taxType', rules: 'required' },
						{ col: '4', name: 'office_address', value: 'addressOffice', rules: 'required' },
						{ col: '4', name: 'bank_guarantee', value: 'guarantee', rules: 'required' },
						{ col: '3', name: 'guarantee_expire_date', value: 'guaranteeExpireDate', rules: 'required', datePicker: true },
						{ col: '3', name: 'phone_number', value: 'telMobileReg', rules: 'required|numeric|length:12', placeholder: this.placeholder.phone_number, mask: '+380 ## ### ## ##' },
						{ col: '3', name: 'office_phone_number', value: 'telOffice', rules: 'required|numeric|length:12', placeholder: this.placeholder.phone_number, mask: '+380 ## ### ## ##' },
						{ col: '3', name: 'emergency_phone_number', value: 'telEmergency', rules: 'required|numeric|length:12', placeholder: this.placeholder.phone_number, mask: '+380 ## ### ## ##' },
						{ col: '3', name: 'email_to_receive_documents', value: 'emailExtra', rules: 'required|email', placeholder: this.placeholder.email_to_receive_documents },
						{ col: '3', name: 'email_optional', value: 'emailOffice', rules: 'required|email', placeholder: this.placeholder.email_optional },
						{ col: '3', name: 'name_signatory_of_the_contract', value: 'fullNameCompany', rules: 'required' }
					]},
					{ title: this.$t("auth.sign_up.bank_details"), fields: [
						{ col: '4', name: 'iban', value: 'iban', rules: 'required|length:29', placeholder: this.placeholder.iban, mask: 'UA## #### #### #### #### #### #### #' },
						{ col: '4', name: 'name_bank', value: 'bank', rules: 'required' },
						{ col: '4', name: 'account_holder', value: 'accountOwner', rules: 'required' }
					]},
					{ title: this.$t("auth.sign_up.additional_info"), fields: [
							{ col: '4', name: 'name_owner', value: 'nameOwner', rules: 'required'},
							{ col: '4', name: 'city', value: 'city', rules: 'required' }
						]}
				]
			},
			items_ownership() {
				return [
					{ id: 0, name: this.$t('auth.sign_up.legal_entity') },
					{ id: 1, name: this.$t('auth.sign_up.self_employed') }
				]
			},
			items_acts_on_the_basis() {
				return [
					{ id: "0", name: this.$t('auth.sign_up.of_the_charter') },
					{ id: "1", name: this.$t('auth.sign_up.extracts_from_ЄDR') }
				]
			}
		},
		created() {
			this.ownership = this.items_ownership[0].id;
			this.form.operatesUnder = this.items_acts_on_the_basis[0].id;
		},
		methods: {
			onSubmit() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;
                registration({...this.form, ownership: this.ownership}).then(() => {
					this.$emit("change-success", true)
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {errors: data.errors})
				}).finally(() => this.loading = false)
			},
			resetForm() {
				this.form = {
					operatesUnder: this.items_acts_on_the_basis[0].id,
					agreement: false
				}

				this.$refs.form.reset();
			},
			closeDialog() {
				this.$emit('close-dialog');
			}
		}
	}
</script>
