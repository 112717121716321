var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-up-content"},[_c('div',{staticClass:"row sign-up-header"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"sign-up-title"},[_vm._v(_vm._s(_vm.$t("auth.sign_up.title")))])]),_c('div',{staticClass:"col-3"},[_c('v-select',{attrs:{"label":_vm.$t("auth.sign_up.ownership"),"name":"ownership","options":_vm.items_ownership,"required":""},on:{"input":function($event){return _vm.resetForm()}},model:{value:(_vm.ownership),callback:function ($$v) {_vm.ownership=$$v},expression:"ownership"}})],1)]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._l((_vm.items.filter(function (el) { return el.ownership_hide !== _vm.ownership; })),function(item,i){return _c('div',{key:i,staticClass:"sign-up-block"},[(item.title)?_c('p',{staticClass:"sign-up-sub-title"},[_vm._v(_vm._s(item.title))]):_vm._e(),_c('div',{staticClass:"row"},_vm._l((item.fields),function(field,i){return _c('div',{key:i,class:field.col ? ("col-" + (field.col)): 'col'},[(field.select)?[_c('v-select',{attrs:{"label":_vm.$t(("auth.sign_up." + (field.name))),"name":field.value,"options":field.items,"required":""},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}})]:(field.datePicker)?[_c('ValidationProvider',{attrs:{"name":_vm.$t(("auth.sign_up." + (field.name))),"rules":field.rules ? field.rules : { required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"app-input"},[(field.name)?_c('label',{staticClass:"app-label"},[_vm._v(" "+_vm._s(_vm.$t(("auth.sign_up." + (field.name))))+": "),(field.required)?_c('sup',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"app-input__control"},[_c('date-picker',{staticClass:"mx-datepicker-small",attrs:{"placeholder":_vm.$t(("auth.sign_up.placeholder." + (field.name))),"lang":_vm.$route.params.lang ? _vm.$route.params.lang : 'uk',"disabled-date":function (date) { return date <= new Date().setHours(0, 0, 0, 0); },"append-to-body":false,"editable":false},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}}),_c('div',{staticClass:"app-input__details"},[(errors[0])?_c('div',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])],1)])]}}],null,true)})]:[_c('ValidationProvider',{attrs:{"name":_vm.$t(("auth.sign_up." + (field.name))),"rules":field.rules ? field.rules : { required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{key:field.name,class:field.rules ? classes : "",attrs:{"label":_vm.$t(("auth.sign_up." + (field.name))),"placeholder":field.placeholder ? field.placeholder : _vm.$t(("auth.sign_up.placeholder." + (field.name))),"name":field.name,"errorMessage":errors[0],"required":field.rules ? true : false,"mask":field.mask ? field.mask : false},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}})]}}],null,true)})]],2)}),0)])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('v-checkbox',{attrs:{"name":"terms_check","label":_vm.$t("auth.sign_up.checkbox"),"required":""},model:{value:(_vm.form.agreement),callback:function ($$v) {_vm.$set(_vm.form, "agreement", $$v)},expression:"form.agreement"}})],1)]),_c('div',{staticClass:"row sign-up-buttons"},[_c('div',{staticClass:"col-auto"},[_c('v-button',{attrs:{"color":"green","outline":"","to":_vm.$i18nRoute({name: 'sign-in'})}},[_vm._v(_vm._s(_vm.$t('auth.sign_up.cancel')))])],1),_c('div',{staticClass:"col-auto"},[_c('v-button',{attrs:{"color":"green white--text","disabled":invalid || !_vm.form.agreement,"loading":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('auth.sign_up.next')))])],1)])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }